@import "src/shared/components/TopNavBar/TopNavBar.scss";
@import "src/shared/containers/MainDrawer/MainDrawer.scss";
@import "src/shared/containers/RightDrawer/RightDrawer.scss";
@import "src/shared/components/MainExpansionPanel/MainExpansionPanel.scss";
@import "src/shared/components/TimeEntryUI/TimeEntryUI.scss";
@import "src/shared/components/TimeEntryUI/TimePicker.scss";
@import "./src/core/App.scss";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #fff !important;
  }
}

tr:hover {
  opacity: 0.8;
}

.wait-container {
  text-align: center;
  display: block;
}
