.summary_wrapper h2 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  color: #15325f;
  text-align: center;
  margin-bottom: 25px;
  text-transform: underline;
}
.summary_wrapper p {
  font-weight: 50;
  font-size: 15px;
  margin-bottom: 10px;
  color: #15325f;
}

.summary_wrapper span {
  color: grey;
  font-weight: 400;
}
